import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, navigate } from 'gatsby'
import { useWindowSize } from '../../../../windowResize'
import {
  dateFormatHandler,
  formatPrice,
  formatCurrency,
  permissionsHandler,
  returnCreditType,
} from '../../../functions'
import axios from 'axios'
import { auth0Roles, strapiURL } from '../../../config'
import { message, Table, Switch } from 'antd'
import { setProducts } from '../../../redux/actions/user'
import globalData from '../../../../globalData.json'
import UpdateEmail from '../../Modals/updateEmail'
import UpdatePasswordModal from '../../Modals/updatePassword'
import CreditDetail from '../../creditDetail'
import moment from 'moment'
import cookie from 'react-cookies'
import useGMT from '../../../hooks/useGTM'
import { paramsReturnHandler } from '../../../data'

const AccountSettingsComponent = ({
  user,
  userRole,
  userProfile,
  setUserProfile,
  location,
}) => {
  let _windowSize = useWindowSize()
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [passwordModalOpen, setPasswordModalOpen] = useState(false)
  const [addonsList, setAddonsList] = useState([])
  const [checkoutLoading, setCheckoutLoading] = useState({
    loading: false,
    id: null,
  })
  const [invoices, setInvoices] = useState([])
  const [showAddons, setShowAddons] = useState(false)
  const storeProducts = useSelector(state => state.user).products
  let largeScreen = _windowSize?.width && _windowSize?.width > 1023
  let products = permissionsHandler(storeProducts, user?.role?.id)
  let subscription = products?.subscriptionPurchased
  const dispatch = useDispatch()
  const { pushCancelSubscriptionEvent } = useGMT()

  useEffect(() => {
    let employerSubscriptionItems = globalData?.EmployerCatalog?.prices || []
    if (subscription) {
      setAddonsList(
        employerSubscriptionItems?.find(
          item => item.price.priceId === subscription.price.priceId
        )?.addOns
      )
      if (
        window?.location?.hostname == 'www.medreps.com' ||
        window?.location?.hostname == 'medreps.com' 
        // || window?.location?.hostname == 'localhost'
      ) {
        handleCancelSubscription()
      }
    }
  }, [subscription])

  const handleCancelSubscription = () => {
    const ChurnBuster = window.ChurnBuster || {}
    ChurnBuster.cancelAttributes = {
      accountId: '454db1ca-3d55-491d-882c-721783a358dd',
      source: 'stripe',
      customerId:
        userProfile?.user?.subscription?.stripe_customer_id || user.customer_id,
      customerEmail: userProfile?.user?.email,
      subscriptionId: subscription?.subscription,
      cancelButtonSelector: '#cancelMembership',
      liveMode: true,
    }

    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src =
      'https://proxy-assets.churnbuster.io/v4/scripts.js?v=1678742553659'
    var insert = document.getElementsByTagName('script')[0]
    insert.parentNode.insertBefore(script, insert)
  }

  useEffect(() => {
    if (location.search && user) {
      let _search = paramsReturnHandler(location.search)
      if (_search.method === 'reactivate') {
        redirectToPortal()
      }
    }
  }, [location, user])

  useEffect(() => {
    if (products?.subscriptionPurchased) {
      setShowAddons(true)
    }
  }, [storeProducts])

  useEffect(() => {
    let customerId

    if (user && userRole == auth0Roles.NamedUser.id) {
      customerId = userProfile?.user?.subscription?.stripe_customer_id
    } else {
      customerId = user?.customer_id
    }

    if (customerId) {
      let _lineItems = []
      if (!_lineItems.length) {
        setLoading(true)
        axios
          .post(strapiURL + '/stripe/invoices', {
            customer_id: customerId,
          })
          .then(res => {
            setLoading(false)
            if (res?.data.charges?.data?.length) {
              res?.data?.charges?.data?.forEach(transaction => {
                if (transaction?.invoice?.lines?.data?.length) {
                  transaction?.invoice.lines.data.forEach(item => {
                    let itemDescription = item.description
                    if (item.type === 'invoiceitem' && item.quantity) {
                      itemDescription =
                        item.quantity + ' \u00d7 ' + item.description
                    }

                    _lineItems.push({
                      ...item,
                      invoice_id: item.id,
                      created: transaction.created,
                      currency: transaction.currency,
                      status: transaction.status?.toUpperCase(),
                      description: itemDescription,
                      invoice_doc: transaction.invoice?.invoice_pdf,
                    })
                  })
                } else {
                  _lineItems.push({
                    ...transaction,
                    invoice_id: transaction?.id,
                    created: transaction.created,
                    currency: transaction.currency,
                    status: transaction.status?.toUpperCase(),
                    invoice_doc: transaction.invoice?.invoice_pdf,
                  })
                }
              })
            }
            _lineItems = _lineItems.sort(
              (a, b) => new Date(b.created) - new Date(a.created)
            )
            setInvoices(_lineItems)
          })
      }
    } else {
      setInvoices([])
    }
  }, [user, userProfile])

  const parseChargedAmount = record => {
    if (
      record.discountable &&
      record.discount_amounts &&
      record.discount_amounts.length > 0
    ) {
      const discount = record.discount_amounts[0]
      if (discount.amount > 0) {
        return record.amount / 100 - discount.amount / 100
      }
    }
    return record.amount / 100
  }

  const buyPlanhandler = values => {
    setSubmitLoading(true)
    let data = {
      stripeCustomerId: user?.customer_id,
      userSubscriptionId: user?.user?.subscription,
    }

    axios
      .post(strapiURL + '/auth0/user/validateAndCreateSubscription', data)
      .then(res => {
        const userData = res.data

        if (userData.isCheckoutRequired) {
          window.location.href = '/employer/membership/'
        } else {
          alert('You already have a subscription with us.')

          // Retrive updated subscription
          axios
            .get(
              strapiURL + `/employers/${user?.user?.id}/subscriptionsAndCredits`
            )
            .then(res => {
              dispatch(setProducts(res?.data))
              navigate('/employer-dashboard/account')
            })
            .catch(err => {
              console.log(
                'Something wrong while retrive subscriptionsAndCredits.'
              )
            })
        }
      })
      .catch(err => {
        setSubmitLoading(false)
        message.error(err?.response?.data?.message[0].error)
      })
  }

  const checkoutHandler = item => {
    setCheckoutLoading({
      loading: true,
      id: item.priceId,
    })

    const addOnItem = {
      ...item,
    }

    if (item.recurring) {
      addOnItem['is_selected'] = true
    } else {
      addOnItem['quantity'] = 1
    }

    localStorage.setItem(
      'emp-subscription',
      JSON.stringify({
        subscription: null,
        addons: [addOnItem],
      })
    )

    axios
      .post(strapiURL + '/stripe/session/checkout', {
        line_items: [
          {
            price: item.priceId,
            quantity: 1,
          },
        ],
        success_url:
          window.location.origin +
          '/thankyou/?success=true&session_id={CHECKOUT_SESSION_ID}',
        cancel_url: window.location.origin,
        customerEmail: userProfile?.user?.email || '',
        mode: item.recurring ? 'subscription' : 'payment',
        customer:
          user?.customer_id?.trim() ||
          userProfile?.user?.subscription?.stripe_customer_id ||
          '',
        clientId: cookie.load('_ga')?.substring(6),
        sessionId: cookie
          .load(
            process.env.GATSBY_GOOGLE_ANALYTICS_MEASUREMENT_ID?.replace(
              'G-',
              '_ga_'
            )
          )
          ?.split('.')[2],
      })
      .then(res => {
        setCheckoutLoading({ ...checkoutLoading, loading: false })
        window.location.href = res?.data?.url
      })
      .catch(err => {
        setCheckoutLoading({
          loading: false,
          id: null,
        })
        message.error('Something wrong while checkout.')
      })
  }

  const handleDownloadInvoice = invoiceUrl => {
    const link = document.createElement('a')
    link.href = invoiceUrl
    link.setAttribute('download', `FileName.pdf`)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  const handleSetBillingConfirm = async checked => {
    setLoading(true)
    try {
      await axios.put(`${strapiURL}/employers/${userProfile.id}`, {
        billingConfirm: checked,
      })
      setUserProfile({
        ...userProfile,
        billingConfirm: checked,
      })
    } catch (error) {
      message.error('Something went wrong.')
    }
    setLoading(false)
  }

  const redirectToPortal = () => {
    pushCancelSubscriptionEvent(products.subscriptionPurchased?.price?.nickname)

    setTimeout(() => {
      axios
        .post(strapiURL + '/stripe/session', {
          customer_id: user?.customer_id,
          redirect_url: `${
            typeof window !== 'undefined' && window.location.origin
          }/thankyou`,
          is_employer: true,
        })
        .then(res => {
          if (typeof window !== 'undefined') {
            window.history.replaceState(
              '',
              '',
              `${window.location.origin}/thankyou/`
            )
            window.location.href = res?.data?.session_url
          }
        })
        .catch(err => {
          console.log(err)
        })
    }, 1000)
  }
  const transactionCols = [
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      render: (text, record, index) => {
        return (
          <p className="text-black font-sans font-semibold mb-0 text-last">
            {record.invoice_id}
          </p>
        )
      },
    },
    {
      title: 'Name ',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (text, record, index) => {
        return (
          <p className="text-black font-sans font-semibold mb-0 text-last">
            {record.description}
          </p>
        )
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record, index) => {
        return (
          <p className="inline-block bg-[#DCE9EE] text-[#00567C] text-location text-last font-sans mb-0 px-2 rounded">
            {record?.type === 'subscription' ? 'Recurring' : 'One time'}
          </p>
        )
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record, index) => {
        return (
          <p className="text-black font-sans font-semibold mb-0 text-last">
            {formatCurrency(parseChargedAmount(record), record.currency)}
          </p>
        )
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record, index) => {
        return (
          <>
            <p className="text-merlot font-sans font-semibold mb-0 text-last">
              {dateFormatHandler(new Date(record.created * 1000))}
            </p>
          </>
        )
      },
    },
    {
      title: 'Payment Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record, index) => {
        return (
          <p className="text-black font-sans font-semibold mb-0 text-last">
            {record.status}
          </p>
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'invoice',
      key: 'invoice',
      render: (text, record) => {
        return record.invoice_doc ? (
          <a onClick={() => handleDownloadInvoice(record.invoice_doc)}>
            Download
          </a>
        ) : (
          <></>
        )
      },
    },
  ]
  const transactionColsMobile = [
    {
      title: '',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      render: (text, record, index) => {
        return (
          <div className="flex flex-col w-full">
            <div className="flex justify-between">
              <div className="flex flex-col mr-3">
                <p className="text-[13px] text-location leading-0 m-0 font-semibold pb-1">
                  Transaction ID
                </p>
                <div className="flex items-center ">
                  <span className="text-[12px] text-black leading-0 ml-1 font-semibold">
                    {record.invoice_id}
                  </span>
                </div>
              </div>
              <div className="flex flex-col mr-3">
                <p className="text-[13px] text-location leading-0 m-0 font-semibold pb-1">
                  Payment Status
                </p>
                <div className="flex items-center">
                  <span className="text-[12px] text-[#52b85b] font-semibold leading-0 ml-1">
                    {record.status}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-8">
              <div className="flex flex-col mr-3">
                <p className="text-[13px] text-location leading-0 m-0 font-semibold pb-1">
                  Name
                </p>
                <div className="flex items-center w-full lg:w-[60%]">
                  <span className="text-[12px] text-black leading-0 ml-1 font-semibold">
                    {record.description}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-8">
              <div className="flex flex-col mr-3">
                <p className="text-[13px] text-location leading-0 m-0 font-semibold pb-1">
                  Type
                </p>
                <div className="flex items-center">
                  <p className="inline-block bg-[#DCE9EE] text-[#00567C] text-location text-last font-sans mb-0 px-2 rounded">
                    {record?.type === 'subscription' ? 'Recurring' : 'One time'}
                  </p>
                </div>
              </div>
              <div className="flex flex-col mr-3">
                <p className="text-[13px] text-location leading-0 m-0 font-semibold pb-1">
                  Amount
                </p>
                <div className="flex items-center">
                  <span className="text-[12px] text-black leading-0 ml-1 font-semibold">
                    {formatCurrency(
                      parseChargedAmount(record),
                      record.currency
                    )}
                  </span>
                </div>
              </div>
              <div className="flex flex-col mr-3">
                <p className="text-[13px] text-location leading-0 m-0 font-semibold pb-1">
                  Date
                </p>
                <div className="flex items-center">
                  <span className="text-[12px] text-black leading-0 ml-1 font-semibold w-max">
                    {dateFormatHandler(new Date(record.created * 1000))}
                  </span>
                </div>
              </div>
            </div>
            {record.invoice_doc && (
              <div className="flex justify-between mt-8">
                <div className="flex flex-col mr-3">
                  <p className="text-[13px] text-location leading-0 m-0 font-semibold pb-1">
                    Action
                  </p>
                  <div className="flex items-center">
                    <a
                      className="text-last font-sans "
                      onClick={() => handleDownloadInvoice(record.invoice_doc)}
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <h1 className="font-playfair text-headingThree text-merlot">Account</h1>

      <div className="w-12/12 lg:w-8/12">
        {loading ? (
          <div
            className="w-3/5 min-h-700px bg-no-repeat bg-cover"
            style={{
              backgroundImage: `url(/loaders/employer-account-loader.png)`,
            }}
          />
        ) : (
          <>
            {subscription ? (
              <div className="w-full border px-8 lg:px-12 py-8 border-searchBorder rounded">
                <div className="w-full flex justify-between">
                  <div className="w-full ">
                    <div className="block md:flex justify-between items-center mb-6">
                      <p className="font-sans text-xl font-bold text-charcoal mb-0">
                        Your Subscription
                      </p>
                      <div className="flex items-center justify-end">
                        <label className="mr-2">
                          Receive billing confirmation emails
                        </label>
                        <Switch
                          checked={userProfile?.billingConfirm}
                          onChange={handleSetBillingConfirm}
                        />
                      </div>
                    </div>
                    {!subscription?.cancelled_at &&
                      subscription?.subscription &&
                      subscription?.expiry && (
                        <div>
                          <p className="w-max text-last font-sans mb-4 px-3 py-1 rounded text-[#6D1E36] bg-[#FFF0F0] mr-5">
                            Next Billing Date:{' '}
                            <span className="font-bold">
                              {moment(subscription?.expiry).format(
                                'YYYY-MM-DD'
                              )}
                            </span>
                          </p>
                          {subscription.isGracePeriod && (
                            <p className="w-max text-last font-sans mb-4 py-1 rounded text-[#6D1E36]  mr-5">
                              <span
                                style={{
                                  fontStyle: `italic`,
                                }}
                              >
                                Payment processing
                              </span>
                            </p>
                          )}
                        </div>
                      )}

                    {!subscription?.subscription && subscription?.expiry && (
                      <div>
                        <p className="w-max text-last font-sans mb-4 px-3 py-1 rounded text-[#6D1E36] bg-[#FFF0F0] mr-5">
                          Expiry Date:{' '}
                          <span className="font-bold">
                            {moment(subscription?.expiry).format('YYYY-MM-DD')}
                          </span>
                        </p>
                      </div>
                    )}

                    {subscription.cancelled_at && (
                      <p className="w-max text-last font-sans mb-4 px-3 py-1 rounded text-[#6D1E36] bg-[#FFF0F0] mr-5">
                        Expire Date:{' '}
                        <span className="font-bold">
                          {moment(subscription.expiry).isValid()
                            ? moment(subscription.expiry).format('YYYY-MM-DD')
                            : ''}
                        </span>
                      </p>
                    )}

                    <div className="flex items-center">
                      <p className="font-lg text-voyage font-semibold mb-2">
                        {subscription.price.nickname}{' '}
                      </p>
                      <div className="ml-5 mb-1">
                        <p className="text-last font-sans text-voyage m-0">
                          Date of Purchase:{' '}
                          <span className="font-bold">
                            {' '}
                            {moment(subscription?.created_at).format(
                              'YYYY-MM-DD'
                            )}
                          </span>
                        </p>
                      </div>
                    </div>

                    {subscription.cancelled_at &&
                      subscription.expiry &&
                      subscription.expiry <= new Date().toISOString() && (
                        <div className="flex items-center">
                          <Link
                            to="/employer/membership"
                            className="inline-block bg-voyage text-white mt-2 text-last font-sans mb-0 px-2 py-1 rounded"
                          >
                            Buy New Plan
                          </Link>
                        </div>
                      )}
                  </div>

                  {subscription.cancelled_at && (
                    <div className="text-right">
                      <div className="text-[#6D1E36]">Canceled</div>
                      <div className="text-xs text-[#6D1E36] my-2 italic">
                        Canceled on{' '}
                        {dateFormatHandler(new Date(subscription.cancelled_at))}
                      </div>
                      {user && userRole !== auth0Roles.NamedUser.id && (
                        <button
                          className="text-white py-2 px-4 bg-button cursor-pointer font-sans font-bold rounded"
                          onClick={() => redirectToPortal()}
                        >
                          Reactivate
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="w-full border px-8 lg:px-12 py-8 border-searchBorder rounded">
                <div className="block md:flex justify-between items-center mb-6">
                  <p className="font-sans text-xl font-bold text-charcoal mb-0">
                    Your Subscription
                  </p>
                  <div className="flex items-center justify-end">
                    <label className="mr-2">
                      Receive billing confirmation emails
                    </label>
                    <Switch
                      checked={userProfile?.billingConfirm}
                      onChange={handleSetBillingConfirm}
                    />
                  </div>
                </div>
                <p className="font-lg text-voyage font-semibold mb-4">
                  No Plan Found.
                </p>
                <button
                  type="button"
                  onClick={buyPlanhandler}
                  className="inline-block bg-[#DCE9EE] text-[#00567C] text-location text-last font-sans mb-0 px-2 py-1 rounded"
                >
                  {submitLoading ? 'Please wait...' : 'Buy Plan'}
                </button>
              </div>
            )}

            <CreditDetail userProfile={userProfile} user={user} />

            <div
              className={`w-full px-8 lg:px-12 py-8 rounded mt-12 bg-navHover ${
                showAddons ? '' : 'hidden'
              }`}
            >
              <p className="font-sans text-2xl font-bold text-white">Add-Ons</p>
              <ul className="ml-0 mb-0">
                {addonsList?.map((addon, addonIndex) => {
                  let addonType = returnCreditType(addon.credit_type).type
                  let _isPurchases
                  if (
                    addonType === 'resumeSearch' &&
                    products?.allowResumeSearch?.price?.priceId ===
                      addon?.priceId
                  ) {
                    _isPurchases = true
                  }

                  if (addonType === 'addonSubscription') {
                    if (userProfile?.staffing_firm && !addon.staffing) {
                      return null
                    }

                    if (!userProfile?.staffing_firm && addon.staffing) {
                      return null
                    }

                    const addonSubscriptionItem =
                      products?.addonSubscriptions?.find(
                        priceItem => priceItem?.price?.priceId === addon.priceId
                      )

                    if (addonSubscriptionItem) {
                      _isPurchases = true
                    }
                  }

                  return (
                    <li
                      key={addonIndex}
                      className="text-white font-semibold flex items-center justify-between mb-3"
                    >
                      <span>{addon.nickname}</span>
                      <button
                        onClick={() => checkoutHandler(addon)}
                        disabled={_isPurchases}
                        className="bg-white py-1 px-2 font-sans text-voyage text-xs font-bold rounded ml-2 text-center w-[7rem]"
                      >
                        {checkoutLoading && checkoutLoading.id === addon.priceId
                          ? 'Loading....'
                          : _isPurchases
                          ? 'Purchased'
                          : `${
                              addonType === 'resumeSearch' ||
                              addonType === 'addonSubscription'
                                ? 'Subscribe'
                                : 'Buy Credits'
                            }`}
                      </button>
                    </li>
                  )
                })}
              </ul>
            </div>
          </>
        )}

        <div className="w-full px-8 lg:px-12 py-6 rounded mt-12 shadow-contact-card">
          <p className="font-sans text-xl text-charcoal font-semibold">
            Support
          </p>
          <p className="text-sm text-navbarItem font-semibold mb-2">
            Have questions?
          </p>
          <ul className="ml-0 mt-0 mb-0">
            <li className="text-jobTag mb-2 text-xs">
              Contact{' '}
              <a
                className="text-jobTag underline"
                href="mailto:info@medreps.com"
              >
                info@medreps.com
              </a>
            </li>
            {user && userRole !== auth0Roles.NamedUser.id && (
              <li className="text-jobTag mb-2 text-xs">
                <button onClick={() => setModalOpen(true)}>
                  <a className="text-jobTag underline">Update your email</a>
                </button>
              </li>
            )}
            {user && userRole !== auth0Roles.NamedUser.id && (
              <li className="text-jobTag mb-2 text-xs">
                <button onClick={() => setPasswordModalOpen(true)}>
                  <a className="text-jobTag underline">Update your password</a>
                </button>
              </li>
            )}
            {subscription && !subscription.cancelled_at && user?.customer_id ? (
              <li
                onClick={() => redirectToPortal()}
                className="text-jobTag underline mb-2 text-xs cursor-pointer"
              >
                Update Payment Details
              </li>
            ) : (
              <></>
            )}
            {subscription && !subscription.cancelled_at && user?.customer_id ? (
              <li
                onClick={() => redirectToPortal()}
                className="text-jobTag underline mb-2 text-xs cursor-pointer"
                id="cancelMembership"
              >
                Cancel Your Plan
              </li>
            ) : (
              <></>
            )}

            <li className="text-jobTag underline mb-2 text-xs">
              <a href="/faq-employer" target="_blank" className="text-jobTag">
                Frequently Asked Questions
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-12 shadow-contact-card rounded overflow-auto">
          <Table
            className="hide-table-header-background hide-table-header-cols-border table-with-pagination"
            columns={largeScreen ? transactionCols : transactionColsMobile}
            dataSource={invoices}
            bordered={false}
            loading={loading ? true : false}
          />
        </div>
      </div>
      <UpdateEmail
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        userProfile={userProfile}
      />
      <UpdatePasswordModal
        modalOpen={passwordModalOpen}
        setModalOpen={setPasswordModalOpen}
        userProfile={userProfile}
      />
    </div>
  )
}
export default AccountSettingsComponent
